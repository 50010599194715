export const config={
  "text": {
    "companyName": "Lloyds",
    "accounting.dataAccess.consent": "By continuing you agree to Lloyds having access to your accounting data from your accounting platform. For more information on how we use your data, refer to our Commercial Banking Online [Terms & Conditions](https://www.lloydsbank.com/assets/assets-business-banking/pdfs/comm00006.pdf) and [Privacy notice](https://www.lloydsbank.com/help-guidance/privacy/data-privacy-notice.html)",
    "accounting.dataAccess.additionalConsent.title": "You have previously authorised access to your accounting software",
    "accounting.dataAccess.additionalConsent.subtitle": "To continue, confirm your consent to use of the required data below.",
    "accounting.dataAccess.dataTypes": [
      "Accounts receivable information",
      "Accounts payable information",
      "Financial summary information",
      "Contact information"
    ],
    "accounting.fileUpload.subtitle": "You can upload requested PDFs, Excel files, or CSVs below.",
    "banking.dataAccess.consent": "By continuing you are permitting Lloyds to access account information. Refer to the Terms and Conditions and Privacy Policy [https://example.com](https://example.com)",
    "banking.dataAccess.additionalConsent.title": "You have previously authorized access to your banking platform",
    "banking.dataAccess.additionalConsent.subtitle": "To continue, confirm your consent to use of the required data below.",
    "banking.dataAccess.dataTypes": [
        "Account holder details",
        "Account details",
        "Transactions"
    ],
    "banking.fileUpload.subtitle": "You can upload requested PDFs, Excel files, or CSVs below.",
    "commerce.dataAccess.consent": "By continuing you are permitting Lloyds to access account information. Refer to the Terms and Conditions and Privacy Policy [https://example.com](https://example.com)",
    "commerce.dataAccess.additionalConsent.title": "You have previously authorized access to your commerce software",
    "commerce.dataAccess.additionalConsent.subtitle": "To continue, confirm your consent to use of the required data below.",
    "commerce.dataAccess.dataTypes": [
        "Company information",
        "Orders & payments",
        "Products",
        "Disputes & transactions",
        "Customers"
    ],
    "businessDocuments.fileUpload.subtitle": "You can upload requested PDFs, Excel files, or CSVs below.",
    "landing.subtitle": "By continuing you are permitting Lloyds to access account information. Refer to the [Terms & Conditions](https://www.lloydsbank.com/assets/assets-business-banking/pdfs/comm00006.pdf) and [Privacy notice](https://www.lloydsbank.com/help-guidance/privacy/data-privacy-notice.html)",
    "landing.title": "Connect your accounting data to Lloyds Connected.",
    "main.subtitle": "We will need ongoing access to your accounting data for Connected",
    "main.title": "Confirm data sharing"
  },
  "theme": {
    "colors": {
      "primary": "#225440"//"#11b679"
    }
  },
  "sourceTypes": {
    "accounting": {
      "optional": false,
      "enableIntegrations": true,
      "enableFileUpload": false,
      "allowedIntegrations": [
        "gbol",
        "trji",
        "akxx",
        "jcrp",
        "hbql"
      ]
    },
    "banking": {
      "optional": false,
      "enableIntegrations": false,
      "enableFileUpload": false
    },
    "commerce": {
      "optional": false,
      "enableIntegrations": false
    },
    "businessDocuments": {
      "optional": false,
      "enableFileUpload": false
    }
  },
  "showSandboxIntegrations": true,
  "showLandingPage": true,
  "nonModal": true,
  "enableAdditionalConsent": false
}
